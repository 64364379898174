var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-8 col-md-8 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2"
  }, [_c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center",
    staticStyle: {
      "text-transform": "uppercase"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("document")) + " ")]), _c('form', {
    staticClass: "login-form"
  }, [_c('div', {
    staticClass: "row d-flex justify-content-start"
  }, [_c('div', {
    staticClass: "col-md-10"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("upload-doc")) + ":")])])])]), _c('div', {
    staticClass: "row d-flex justify-content-start"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('ul', [_c('li', [_vm._v(" " + _vm._s(_vm.$t("other-doc")) + " "), _c('img', {
    directives: [{
      name: "tippy",
      rawName: "v-tippy"
    }],
    staticClass: "mb-1",
    attrs: {
      "content": "\n                      <b> Contoh:</b><br />\n                        <a> Salinan MyKad / Sijil Kematian Waris Faraid, </a>\n\n                        <a>Salinan MyKad Penerima,</a>\n                        <a> Sijil Nikah,</a>\n                        <a> Sijil Pengangkatan Anak,</a>\n                        <a> Kad OKU,</a>\n                        <a> Perjanjian Pinjaman,</a>\n                        <a> Sijil Penceraian Perkahwinan</a>",
      "height": "20",
      "src": "/images/icon/info-button.png"
    }
  }), [0, 1].includes(_vm.amendment_status) ? _c('b-button', {
    staticClass: "btn-sm btn-info d-block",
    on: {
      "click": _vm.addDocument
    }
  }, [_vm._v(_vm._s(_vm.$t("add-doc")))]) : _vm._e()], 1)])])]), _c('div', {
    staticClass: "row d-flex justify-content-end mb-4"
  }), _c('div', {
    staticClass: "row mt-2"
  }, [_c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table table-hover table-sm"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(_vm._s(_vm.$t("index")))]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(_vm._s(_vm.$t("doc-name")))]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("action")) + " ")])])]), _vm.doc_array.length == 0 ? _c('tbody', [_c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "4"
    }
  }, [_c('h6', {
    staticClass: "mt-5 mb-3"
  }, [_vm._v(_vm._s(_vm.$t("no-docs")))])])])]) : _vm._e(), _vm.doc_array.length > 0 ? _c('tbody', _vm._l(_vm.doc_array, function (item, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm._v(_vm._s(item.description))]), _c('td', {
      staticClass: "text-center"
    }, [_c('div', {
      staticClass: "btn-group",
      attrs: {
        "role": "group",
        "aria-label": "Basic example"
      }
    }, [_c('b-button', {
      staticClass: "btn-sm btn-secondary",
      on: {
        "click": function ($event) {
          return _vm.displayDoc(item);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": "/images/icon/view.svg",
        "alt": "",
        "height": "13"
      }
    })]), [0, 1, 4].includes(_vm.amendment_status) ? _c('b-button', {
      staticClass: "btn-sm btn-danger",
      on: {
        "click": function ($event) {
          return _vm.deleteDoc(item.id, index, item.type);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": "/images/icon/trash.png",
        "alt": "",
        "height": "13"
      }
    })]) : _vm._e()], 1)])]);
  }), 0) : _vm._e()])])])])])]), _c('div', {
    staticClass: "row mt-3"
  }, [_c('div', {
    staticClass: "col-lg-12 text-right"
  }, [_c('div', {
    staticClass: "form-group"
  }, [[0, 1].includes(_vm.amendment_status) ? _c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "to": {
        name: 'pindaan.part_h',
        params: {
          wasiat_id: this.wasiat_id
        },
        query: {
          pengesahan: this.pengesahan
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("save-next")))]) : _vm._e()], 1)])])])]), _c('b-modal', {
    ref: "addDocument",
    attrs: {
      "title": _vm.$t('add-doc'),
      "ok-title": _vm.$t('save'),
      "cancel-title": _vm.$t('cancel')
    },
    on: {
      "ok": _vm.saveDocument,
      "cancel": _vm.Batal
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.doc.description.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "description"
    }
  }, [_vm._v(_vm._s(_vm.$t("doc-name"))), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.doc.description.$model,
      expression: "$v.doc.description.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "disabled": _vm.doc.type,
      "type": "text",
      "id": "description"
    },
    domProps: {
      "value": _vm.$v.doc.description.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.doc.description, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.doc.description.$error && !_vm.$v.doc.description.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("doc-name")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]), _c('label', {
    staticClass: "upload-photo-dark-text btn-sm btn-info btn-upload",
    attrs: {
      "for": "upload-photo"
    }
  }, [_vm._v("+ " + _vm._s(_vm.$t("add-doc"))), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    attrs: {
      "type": "file",
      "name": "photo",
      "id": "upload-photo",
      "accept": "image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
    },
    on: {
      "change": function ($event) {
        return _vm.filesChange($event.target.files);
      }
    }
  }), _c('div', {
    staticClass: "mt-3"
  }, [_vm._v(_vm._s(_vm.$t("selected-file")) + ": " + _vm._s(_vm.file ? _vm.file : "-"))])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }